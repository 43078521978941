import React from 'react';
import { H2, IconCalendar, IconEdit3, IconEye, IconThumbSup } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from '../styles.less';
import { Card } from './Card';

const HowItWorks = () => {
  const t = useFormatMessage();
  
  return (
    <section className="howItWorks">
      <div className="content_flow">
        <div className={styles.howItWorksContainer}>
          <h2 className="m-0">{t({ id: 'recruiter.how.title' })}</h2>
          <div className={styles.howItWorksContent}>
            <Card 
              icon={<IconEdit3 className="text-primary-500" />} 
              title={t({ id: ' home.howItWorks.signUp.title' })} 
              description={t({ id: 'home.howItWorks.signUp.description' })} 
            />
            <Card 
              icon={<IconCalendar className="text-primary-500" />} 
              title={t({ id: 'home.howItWorks.bookAppointment.title' })}
              description={t({ id: 'home.howItWorks.bookAppointment.description' })}
            />
            <Card 
              icon={<IconThumbSup className="text-primary-500" />} 
              title={t({ id: 'home.howItWorks.getResponse.title' })}
              description={t({ id: 'home.howItWorks.getResponse.description' })}
            />
            <Card 
              icon={<IconEye className="text-primary-500" />} 
              title={t({ id: ' home.howItWorks.explore.title' })}
              description={t({ id: ' home.howItWorks.explore.description' })}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export { HowItWorks };