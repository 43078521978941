import React from 'react';
import { string, object } from 'prop-types';
import classnames from 'classnames';

// Icons
import './icons.svg';

// Styles & Tranlations
import styles from './styles.less';

/**
 * Icon
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.className
 * @param {object} props.style
 */
const Icon = ({ name, className, style }) => (
  <svg className={classnames(styles.icon, className)} style={style}>
    <use xlinkHref={`#icons_${name}`} />
  </svg>
);

Icon.propTypes = {
  name: string.isRequired,
  className: string,
  style: object,
};

export default Icon;
