import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';
import styles from './styles.less'
import Button from '../Button';

const DropdownMenu = ({
  children,
  menuItems,
  triggerClass
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  return (
    <div>
      <Button type='submit'
              onClick={toggleDropdown}
              className={`${styles.dropbtn} ${triggerClass}`}
              variant="tonal">
        {children}
        <ChevronDown/>
      </Button>
      {isDropdownOpen && (
        <div className={styles.dropdownContent}>
          {menuItems.map((item, index) => (
            <a key={item.name+index.toString()} className="dropdown" href={item.href} onClick={item.onClick}>{item.label}</a>
          ))}
        </div>
      )}
    </div>
  )
}

export default DropdownMenu;
