import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { string } from 'prop-types';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { useFormatMessage } from 'react-intl-hooks';
import { getLocale } from "@/utils/localStorage";
import toJS from '../../../app/utils/toJS';
import Header from '../../components/HeaderV2';
import Footer from '../../components/Footer';
import { appSelectors } from '../../../app/store/app';
import phoneVideo from '../../assets/video/phone_bd.gif';
import { Jobdatings } from './components/Jobdatings';
import { Verbatim } from './components/Verbatim';
import { Partners } from './components/Partners';
import { SearchEvents } from './components/SearchEvents';
import { HowItWorks } from './components/HowItWorks';
import { trackingUserEventData } from '@/utils/analytics/trackers/trackingUserEventData';
import { GTM_EVENTS, GTM_EVENTS_NAME } from '@/utils/constants';
import { trackGtmEvent } from '@/utils/pixels/helloWork';

const Home = ({
  countAppointments,
}) => {
  const t = useFormatMessage();

  useEffect(() => {
    trackGtmEvent(GTM_EVENTS.virtualPageDisplay, {
      ...trackingUserEventData(),
      page_subject: 'home',
      page_category: 'landing'
    })
  }, []);

  const Badge = (
    <div className="countmeet">
      <span><b role="img">😎</b>{countAppointments}</span>
      {t({ id: 'footer.interview' })}
    </div>
  );

  return (
    <>
      <Helmet htmlAttributes={{ lang: getLocale() }}>
        <meta charSet="utf-8" />
        <title>{`Seekube - ${t({ id: 'home.jobdating.title' })}`}</title>
        <meta name="description" content={t({ id: 'home.jobdating.text' })} />
      </Helmet>
      <Header badge={<Badge />}>
        <div className="col-left">
          <div className="text">
            <h1>
              {t({ id: 'home.header.h1' })}
            </h1>
            <div className="baseline">
              {t({ id: 'home.header.baseline' })}
            </div>
          </div>
        </div>
        <div className="phone">
          <div>
            <img src={phoneVideo} alt="Mobile" />
          </div>
        </div>
      </Header>
      <SearchEvents />
      <HowItWorks />
      <Jobdatings />
      <Verbatim />
      <Partners />
      <Footer />
    </>
  )
}

Home.propTypes = {
  countAppointments: string
}

const mapDispatchToProps = {
  push,
};

const mapStateToProps = createStructuredSelector({
  countAppointments: appSelectors.getCountAppointments,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  injectIntl,
)(toJS(Home));
