export const AUTH_LOGIN = 'app/store/auth/AUTH_LOGIN';
export const AUTH_LOGIN_OAUTH = 'app/store/auth/AUTH_LOGIN_OAUTH';
export const AUTH_LOGIN_SUCCESS = 'app/store/auth/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'app/store/auth/AUTH_LOGIN_ERROR';
export const AUTO_LOGIN = 'app/store/auth/AUTO_LOGIN';

export const AUTH_RESET_PASSWORD = 'app/store/auth/AUTH_RESET_PASSWORD';
export const AUTH_CHANGE_PASSWORD = 'app/store/auth/AUTH_CHANGE_PASSWORD';
export const AUTH_RESET_PASSWORD_STATUS = 'app/store/auth/AUTH_RESET_PASSWORD_STATUS';
export const AUTH_CHECK_INVITATION = 'app/store/auth/AUTH_CHECK_INVITATION';
export const AUTH_CHECK_INVITATION_SUCCESS = 'app/store/auth/AUTH_CHECK_INVITATION_SUCCESS';
export const AUTH_CHECK_RESET_TOKEN = 'app/store/auth/AUTH_CHECK_RESET_TOKEN';
export const AUTH_CHECK_RESET_TOKEN_SUCCESS = 'app/store/auth/AUTH_CHECK_RESET_TOKEN_SUCCESS';

export const AUTH_SIGNUP = 'app/store/auth/AUTH_SIGNUP';
export const AUTH_SSO = 'app/store/auth/AUTH_SSO';
export const AUTH_SIGNUP_PROVIDER = 'app/store/auth/AUTH_SIGNUP_PROVIDER';

export const AUTH_REFRESH_USER = 'app/store/auth/AUTH_REFRESH_USER';

export const SWITCH_AUTH = 'app/store/auth/SWITCH_AUTH';
export const SWITCH_AUTH_SUCCESS = 'app/store/auth/SWITCH_AUTH_SUCCESS';
export const SWITCH_AUTH_ERROR = 'app/store/auth/SWITCH_AUTH_ERROR';

export const SWITCH_EXIT = 'app/store/auth/SWITCH_EXIT';
export const SWITCH_EXIT_SUCCESS = 'app/store/auth/SWITCH_EXIT_SUCCESS';

export const AUTH_LOGOUT = 'app/store/auth/AUTH_LOGOUT';
export const AUTH_LOGOUT_USER = 'app/store/auth/AUTH_LOGOUT_USER';

export const AUTH_CHANGE_EMAIL = 'app/store/auth/AUTH_CHANGE_EMAIL';

export const AUTO_LOGIN_FROM_V1 = 'app/store/auth/AUTO_LOGIN_FROM_V1';
export const AUTO_LOGIN_V1 = 'app/store/auth/AUTO_LOGIN_V1';

export const TOGGLE_SETTINGS = 'app/store/auth/TOGGLE_SETTINGS';
export const CLOSE_SETTINGS = 'app/store/auth/CLOSE_SETTINGS';
export const CLOSE_IE_ALERT = 'app/store/auth/CLOSE_IE_ALERT';
export const CLOSE_COOKIE_ALERT = 'app/store/auth/CLOSE_COOKIE_ALERT';
export const SET_AGENDA_VIEW = 'app/store/auth/SET_AGENDA_VIEW';
export const CLOSE_FEEBACK_ABOUT_CANDIDATES = 'app/store/auth/CLOSE_FEEBACK_ABOUT_CANDIDATES';
export const SET_ATS_DEFAULT_REVIEWER = 'app/store/auth/SET_ATS_DEFAULT_REVIEWER';
