import React from 'react';
import { object, string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { getImageSize } from '../../../app/utils/imageHandler';

import styles from './styles.less';

class Image extends React.PureComponent {
  render() {
    const { className, image } = this.props;

    return (<div className={[styles.image, className].join(' ')}>
      <div className={styles.content}>
        {image}
      </div>
    </div>);
  }
}

Image.propTypes = {
  className: string,
  image: object,
};

const getCdnUrl = (src, size) => {
  if (!isEmpty(src) && src.indexOf('cdn.seekube.com') > -1 && size) {
    const cdnSize = getImageSize(size);

    return `https://aqfjyrmoen.cloudimg.io/width/${cdnSize}/x/${src}`;
  }

  return src;
};

export { getCdnUrl };

export default Image;
