import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CustomizeCookie } from '@seekube-tech/cookie-consent';
import { useFormatMessage } from 'react-intl-hooks';

// Store
import { appSelectors } from '../../../app/store/app';
import { changeLocale } from '../../../app/containers/LanguageProvider/actions';

// Images
import iconSeekube from '../../assets/svg/icon_seekube.svg';
import fb from '../../assets/svg/fb.svg';
import twitter from '../../assets/svg/twitter.svg';
import linkedinFooter from '../../assets/svg/linkedin_f.svg';

import messages from './messages';

/* eslint-disable */
function Footer({ type = 'candidate', countAppointments, background = '#FFF', changeLocale }) {
  /* Vars */

  const [isVisibleCookie, setIsVisibleCookie] = useState(false);
  const t = useFormatMessage();

  useEffect(() => {
    if (window.location.href.indexOf('displayCookie=true') > -1) {
      setIsVisibleCookie(true);
    }
  }, []);


  /* Functions */

  /**
   * When call, open customize cookie modal
   */
  const handleOpenCookie = () => {
    setIsVisibleCookie(true);
  };

  /**
   * When call, close customize cookie modal
   */
  const handleCloseCookie = () => {
    setIsVisibleCookie(false);
  };

  /* Render */

  return (
    <footer style={{ backgroundColor: background }}>
      <div className="content_flow">
        <nav>
          <ul>
            <li>
              <img src={iconSeekube} />
              <div>
                <span>{countAppointments}</span>
                <FormattedMessage
                  {...messages.interview}
                  children={(message) => <React.Fragment>{message}</React.Fragment>}
                />
              </div>
            </li>
            <li>
              <h3><FormattedMessage {...messages.about} /></h3>
              <ul>
                <li>
                  <a target="_blank" href={t({ id: 'url.we.recruit' })}>
                    <FormattedMessage {...messages.weRecruit} />
                  </a>
                </li>
                {type === 'candidate' ?
                  (<React.Fragment>
                    <li><Link to="/recruiters"><FormattedMessage {...messages.recruiter} /></Link></li>
                    <li><a title="Help center" href="https://intercom.help/seekube" target="_blank"><FormattedMessage {...messages.help} /></a></li>
                  </React.Fragment>) :
                  (<React.Fragment>
                    <li><Link to="/recruiters/events"><FormattedMessage {...messages.calendar} /></Link></li>
                    <li><Link to="/"><FormattedMessage {...messages.candidate} /></Link></li>
                    <li><a title="Help center" href="https://intercom.help/seekube/fr/collections/2627091-je-suis-recruteur" target="_blank"><FormattedMessage {...messages.help} /></a></li>
                  </React.Fragment>)
                }
              </ul>
            </li>
            <li>
              <h3><FormattedMessage {...messages.legal} /></h3>
              <ul>
                <li><Link to="/legals"><FormattedMessage {...messages.legalMention} /></Link></li>
                <li><Link to="/terms"><FormattedMessage {...messages.termOfUse} /></Link></li>
                <li><Link to="/privacy-policy"><FormattedMessage {...messages.privacy} /></Link></li>
                <li><a role="button" onClick={handleOpenCookie}><FormattedMessage {...messages.cookie} /></a></li>
              </ul>
            </li>
            <li>
              <h3><FormattedMessage {...messages.followUs} /></h3>
              <div className="follow" style={{ marginBottom: '10px' }}>
                <a target="_blank" href="https://fr-fr.facebook.com/Seekube"><img src={fb} /></a>
                <a target="_blank" href="https://twitter.com/seekube?lang=fr"><img src={twitter} /></a>
                <a target="_blank" href="https://www.linkedin.com/company/seekube/"><img src={linkedinFooter} /></a>
              </div>
              © {moment().format('YYYY')} Seekube

              <div className="lang">
                <a role="button" tabIndex={0} onClick={() => changeLocale('fr')}>FR</a> | <a role="button" tabIndex={0} onClick={() => changeLocale('en')}>EN</a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div className='subFooter_public'>
        <div className='content'>
          <ul>
            <li><span><FormattedMessage {...messages.media} /> : </span><a href='https://www.blogdumoderateur.com/' target='_blank'> BDM </a>  |  <a href='https://www.letelegramme.fr/' target='_blank'> Le Télégramme </a>  |  <a href='https://www.bretagne.com/fr' target='_blank'> Bretagne.com </a>  |  <a href='https://essence-moins-chere.letelegramme.fr/' target='_blank'> Essence Moins Chère </a>  |  <a href='https://www.tebeo.bzh/' target='_blank'> Tébéo </a>  |  <a href='https://www.tebesud.bzh/' target='_blank'> Tébésud </a>  |  <a href='https://plages.letelegramme.fr/' target='_blank'> Tous à la plage ! </a>  |  <a href='https://www.letelegramme.fr/voile/' target='_blank'> Voile </a>  |  <a href='https://randonnee.letelegramme.fr/' target='_blank'> Randonnée en Bretagne </a>  |  <a href='https://www.meretmarine.com/fr' target='_blank'> Mer et Marine </a>  |  <a href='https://www.bretagne.com/fr/bretagne-magazine' target='_blank'> Bretagne Magazine </a>  |  <a href='https://www.lemensuel.com/rennes' target='_blank'> Le Mensuel de Rennes </a>  |  <a href='https://www.avispublicsdebretagne.com/' target='_blank'> Bretagne Marchés Publics </a>  |  <a href='https://www.regions-annonceslegales.com/' target='_blank'> Régions Annonces Légales </a>  |  <a href='https://www.digicairn.fr/' target='_blank'> Digicairn </a> </li>
          </ul>
          <ul>
            <li><span><FormattedMessage {...messages.jobs} /> : </span><a href='https://www.hellowork.com/fr-fr/' target='_blank'>HelloWork</a>  |  <a href='https://basile.io/' target='_blank'>Basile</a>  |  <a href='https://www.jobijoba.com/fr/' target='_blank'>JobiJoba</a>  |  <a href='https://www.maformation.fr/' target='_blank'>MaFormation</a>  |  <a href='https://diplomeo.com/' target='_blank'>Diplomeo</a>  |  <a href='https://smartforum.hellowork.com/' target='_blank'>SmartForum</a></li>
          </ul>
          <ul>
            <li><span><FormattedMessage {...messages.sports} /> : </span><a href='https://footamateur.letelegramme.fr/' target='_blank'>Foot Amateur Bretagne</a>  |  <a href='https://www.ocsport.com/fr/' target='_blank'>OC Sport Pen Duick</a>  |  <a href='https://www.routedurhum.com/fr' target='_blank'>Route du Rhum - Destination Guadeloupe</a>  |  <a href='https://www.lasolitaire.com/' target='_blank'>La Solitaire du Figaro</a></li>
          </ul>
          <ul>
            <li><span><FormattedMessage {...messages.corporate} /> : </span><a href='https://www.hellowork-group.com/' target='_blank'>HelloWork Group</a>  |  <a href='https://www.groupe-telegramme.com/' target='_blank'>Groupe Télégramme</a>  |  <a href='https://www.viamedia-publicite.com/' target='_blank'>Viamédia</a>  |  <a href='https://www.rivacom.fr/' target='_blank'>Rivacom</a></li>
          </ul>
        </div>
      </div>

      <CustomizeCookie
        isVisible={isVisibleCookie}
        onClose={handleCloseCookie}
        labels={{
          title: t({ id: 'customizeModal.title' }),
          description: t({ id: 'customizeModal.description' }),
          fonctional: {
            label: t({ id: 'customizeModal.fonctional.label' }),
            description: t({ id: 'customizeModal.fonctional.description' }),
          },
          statistics: {
            label: t({ id: 'customizeModal.statistics.label' }),
            description: t({ id: 'customizeModal.statistics.description' }),
          },
          marketing: {
            label: t({ id: 'customizeModal.marketing.label' }),
            description: t({ id: 'customizeModal.marketing.description' }),
          },
          actions: {
            findMore: t({ id: 'customizeModal.actions.findMore' }),
            refuse: t({ id: 'customizeModal.actions.refuse' }),
            accept: t({ id: 'customizeModal.actions.accept' }),
          },
        }}
      />
    </footer>
  );
}

Footer.propTypes = {
  countAppointments: string,
};

const mapStateToProps = createStructuredSelector({
  countAppointments: appSelectors.getCountAppointments,
});

const mapDispatchToProps = {
  changeLocale,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Footer);
