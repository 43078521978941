import React from 'react';
import { any, bool } from 'prop-types';
import DOMPurify from 'dompurify';

const Html = (props) => {
  if (props.clean) {
    return String(props.value).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  }

  if (props.raw) {
    return (<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.value) }} />);
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.value) }} className="htmlWrapper" />
  );
};

Html.propTypes = {
  raw: bool,
  clean: bool,
  value: any,
};

Html.defaultProps = {
  clean: false,
};

export default Html;
