import React from 'react';
import styles from './styles.less';

export default function Button ({
  children,
  className,
  onClick,
  type,
  variant = 'fill',
  linkTo
}) {
  if (linkTo) {
    return <a className={`${styles.btn} ${className} ${styles[variant]}`} href={linkTo}>{children}</a>
  }
  return (
    <button type={type} className={`${styles.btn} ${className} ${styles[variant]}`} onClick={onClick}>
      {children}
    </button>
  )
}
