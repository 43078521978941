import { H1, H4 } from '@seekube-tech/ui-kit';
import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { objectToParams } from '@/utils/url';
import EventsSearching from '@/components/SearchEvents';
import useEventsSearchEngine from '@/utils/hooks/useEventsSearchEngine';
import { useIsMobile } from '@/utils/MediaQueries';

const SearchEvents = () => {
  const t = useFormatMessage();
  const [filters, setFilters] = useState(null);
  const {
    events,
  } = useEventsSearchEngine({
      filters: filters?.contracts || [],
      MOBILITY: filters?.localisations || [],
      keywords: filters?.keywords || '',
      facetKey: filters?.facetKey || '',
  });
  const isWiderThanMobile = !useIsMobile();
  const history = useHistory();
  

  const onFilterChange = (filters, facetKey) => {
    setFilters({ ...filters, facetKey });
  }

  return (
    <section className="events">
      <div className="content-flow">
        <div className="searchEngine">
          <div className="title">
            { isWiderThanMobile ? 
              <H1 className="text-basic-white">
                {t({ id: 'searchEngine.offers.title' })}
              </H1> : 
              <H4 className="text-basic-white">
                {t({ id: 'searchEngine.offers.title' })}
              </H4>
            }
            {isWiderThanMobile && <H4 className="text-basic-white">
              {t({ id: 'searchEngine.offers.subtitle' })}
            </H4>}
          </div>
          <div className={`flex justify-center ${!isWiderThanMobile && 'w-full'}`}>
            <EventsSearching.SearchEngine
              onFilterChange={onFilterChange}
              onSubmit={(queries) => history.replace(`/search-event?${objectToParams(queries)}`)}
              facets={events.data?.pages?.[0]?.offersFacets || {}}
              contracts={filters?.contracts}
              localisations={filters?.localisations}
              keywords={filters?.keywords || ''}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export { SearchEvents };