import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import bgVerbatim from '../../../assets/svg/bg_verbatim.svg';
import seek from '../../../assets/images/seek.png';
import verbaworm from '../../../assets/svg/verbaworm.svg';
import profil from '../../../assets/images/profil1.png';
import dotred from '../../../assets/images/dotred.png';
import profil2 from '../../../assets/images/profil2.png';
import profil3 from '../../../assets/images/profil3.png';
import profil4 from '../../../assets/images/profil4.png';
import profil5 from '../../../assets/images/profil5.png';

const Verbatim = () => {
  const t = useFormatMessage();

  return (
    <section className="verbatim">
      <img className="bg_deco" alt="deco" src={bgVerbatim} />
      <img className="deco1" alt="deco" src={seek} />
      <img className="deco2" alt="deco" src={verbaworm} />
      <div className="content_flow">
        <h2>{t({ id: 'home.verbatim.title' })}</h2>
        <ul className="row1">
          <li>
            <div className="container">
              {t({ id: 'home.verbatim1.text' })}
            </div>
            <div className="persona">
              <img src={profil} alt="profile"/>
              <div className="identity">
                {t({ id: 'home.verbatim1.name' })}
                {t({ id: 'home.verbatim1.title' })}
              </div>
            </div>
            <img className="dot" src={dotred} alt="dot" />
          </li>
          <li>
            <div className="container">
              {t({ id: 'home.verbatim2.text' })}
            </div>
            <div className="persona">
              <img src={profil2} alt="profile" />
              <div className="identity">
                {t({ id: 'home.verbatim2.name' })}
                {t({ id: 'home.verbatim2.title' })}
              </div>
            </div>
          </li>
          <li>
            <div className="container">
              {t({ id: 'home.verbatim3.text' })}
            </div>
            <div className="persona">
              <img src={profil3} alt="profile" />
              <div className="identity">
                {t({ id: 'home.verbatim3.name' })}
                {t({ id: 'home.verbatim3.title' })}
              </div>
            </div>
          </li>
        </ul>
        <ul className="row2">
          <li>
            <div className="container">
              {t({ id: 'home.verbatim4.text' })}
            </div>
            <div className="persona">
              <img src={profil4} alt="profile" />
              <div className="identity">
                {t({ id: 'home.verbatim4.name' })}
                {t({ id: 'home.verbatim4.title' })}
              </div>
            </div>
          </li>
          <li>
            <div className="container">
              {t({ id: 'home.verbatim5.text' })}
            </div>
            <div className="persona">
              <img src={profil5} alt="profile" />
              <div className="identity">
                {t({ id: 'home.verbatim5.name' })}
                {t({ id: 'home.verbatim5.title' })}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export { Verbatim };
