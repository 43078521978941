/**
 * Create the store with dynamic reducers
 */
import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router/immutable';
import persistState from 'redux-localstorage';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import rootSaga from '../app/sagas';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

export default function configureStore(initialState = {}, localHistory) {
  const localStorageConfig = {
    slicer: (paths) => (state) => (paths ? state.filter((v, k) => paths.indexOf(k) > -1) : state),
    serialize: (subset) => JSON.stringify(subset.toJS()),
    deserialize: (serializedData) => fromJS(JSON.parse(serializedData)),
    merge: (initialState, persistedState) => initialState.mergeDeep(persistedState),
  };

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(localHistory),
  ];

  const enhancers = [
    applyMiddleware(...middlewares),
    persistState(['language'], localStorageConfig),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(localHistory),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );

  // Register Saga
  sagaMiddleware.run(rootSaga)

  return store;
}
