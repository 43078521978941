import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import tech from '../../../assets/images/tech.png';
import business from '../../../assets/images/business.png';
import inge from '../../../assets/images/inge.png';
import indecis from '../../../assets/images/indecis.png';
import { track } from '../../../utils/analytics';
import { ANALYTICS_ALL } from '../../../utils/constants';
import { getAppBaseUrl } from '../../../utils/hostname';

const Jobdatings = () => {
  const t = useFormatMessage();
  const appBaseUrl = getAppBaseUrl();
  
  const tags = {
    tech: t({ id: 'home.tags.tech' }).split(';'),
    business: ['Achats / Suppply Chain', 'Commerce', 'Conseil', 'Digital', 'Finance', 'Juridique', 'Marketing & Communication', 'Ressources Humaines'],
    engineer: ['Aéronautique', 'Aérospatial', 'Automatique / Robotique / Ia', 'Mécanique', 'Chimie / Génie Biologique', 'Transports / Logistique'],
  };
  
  const ParticipateBtn = () => (
    <a
      href={`${appBaseUrl}/auth/signup/candidate`}
      onClick={() => track({
        name: ANALYTICS_ALL.CLICKED_SIGNUP,
        properties: {
          provider: 'mail',
        },
      })}
    >
      {t({ id: 'home.participate' })}
    </a>
  );
  
  return (
    <section className="jobdatings">
      <div className="content_flow">
        <h2>{t({ id: 'home.jobdating.title' })}</h2>
        <p>{t({ id: 'home.jobdating.text' })}</p>
        <ul>
          <li>
            <img src={tech} alt="tech" />
            <span>{t({ id: 'event.category.tech' })}</span>
            <div className="overlay">
              <h2>{t({ id: 'event.category.tech' })}</h2>
              {tags.tech.map((tag) => (<label key={tag}>{tag}</label>))}
              <ParticipateBtn />
            </div>
          </li>
          <li>
            <img src={business} alt="Business" />
            <span>{t({ id: 'event.category.business' })}</span>
            <div className="overlay">
              <h2>{t({ id: 'event.category.business' })}</h2>
              {tags.business.map((tag) => (<label key={tag}>{tag}</label>))}
              <ParticipateBtn />
            </div>
          </li>
          <li>
            <img src={inge} alt="Engineering" />
            <span>{t({ id: 'event.category.engineering' })}</span>
            <div className="overlay">
              <h2>{t({ id: 'event.category.engineering' })}</h2>
              {tags.engineer.map((tag) => (<label key={tag}>{tag}</label>))}
              <ParticipateBtn />
            </div>
          </li>
          <li>
            <img src={indecis} alt="other" />
            <span>{t({ id: 'event.category.other' })}</span>
            <div className="overlay">
              <h2>{t({ id: 'event.category.other' })}</h2>
              <ParticipateBtn />
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

export { Jobdatings };