import { USERS_TYPE } from '@/utils/constants';
import { authLogoutUser } from '@/store/auth/actions';
import { store } from '@/configureStore';
import { isEmpty } from 'lodash';
import { initialState } from '@/store/auth/reducer';
import { LOCAL_STORAGE } from '@/utils/localStorage';

export const redirectUnAuthUser = (user) => {
  store.dispatch(authLogoutUser());
  
  if (isEmpty(user)) {
    return window.location.replace('/auth/login/candidate');
  }
  
  const savedLocation = window.location.pathname;
  const userType = user?._currentOrganization ? USERS_TYPE.recruiter : USERS_TYPE.candidate;
  setUserConnectionStatus(false);
  
  localStorage.setItem('redux', JSON.stringify({ ...JSON.parse(window.localStorage.getItem('redux')), auth: initialState }));
  return window.location.replace(`/auth/login/${userType}?redirect=${savedLocation}`);
}

export const setUserConnectionStatus = (isConnected = true, user = null) => {
  if (isConnected && isEmpty(user)) {
    return;
  }
  window.localStorage.setItem(LOCAL_STORAGE.global.isConnected, isConnected ? 'true' : 'false');
}