import React, { useState } from 'react';
import { any, string } from 'prop-types';
import { Link } from 'react-router-dom';

import { useIsMobile } from '@/utils/MediaQueries';
import { useFormatMessage } from 'react-intl-hooks';
import { ANALYTICS_ALL } from '../../utils/constants';
import { track } from '../../utils/analytics';

import face3 from '../../assets/images/face3.png';
import face2 from '../../assets/images/face2.png';
import face1 from '../../assets/images/face1.png';
import wormblue from '../../assets/svg/wormblue.svg';
import face4 from '../../assets/images/face4.png';
import seek from '../../assets/images/seek.png';
import faces from '../../assets/images/faces.png';
import logoSeekube from '../../assets/svg/logo_seekube.svg';

import DropdownMenu from '../DropdownMenu';
import Modal from '../Modal';
import Button from '../Button';

const Header = ({ children, type = 'home', badge = null }) => {
  const isMobile = useIsMobile();
  const [connectionType, setConnectionType] = useState(null);
  const t = useFormatMessage();

  const handleChangeConnection = (event) => {
    setConnectionType(event.target.value);
  }

  const homeLinks = () => (
      <>
        <li style={{ marginRight: 25 }}>
          <Link to="/recruiters">
            {t({ id: 'header.menu.recruiters' })}
          </Link>
        </li>
        <li style={{ marginRight: 25 }}>
          <Link to="/schools">
            {t({ id: 'header.menu.schools' })}
          </Link>
        </li>
      </>
    )

  const desktopButtons = () => (
      <div className="auth-section">
        <Button className="w-full"
                variant="tonal"
                linkTo="https://app.seekube.com/auth/signup/candidate">
          {t({ id: 'header.menu.signup_candidate' })}
        </Button>
        <DropdownMenu menuItems={[{
          href: "https://app.seekube.com/auth/login/candidate",
          label:  t({ id: 'header.menu.connect_candidate' }),
          onClick: () => track({
            name: ANALYTICS_ALL.CLICKED_SIGNIN,
          })
        },
          {
            href: "https://app.seekube.com/auth/login/recruiter",
            label: t({ id: 'header.menu.connect_recruiter' })
          },
          {
            href: "https://app.seekube.com/auth/login/owner",
            label: t({ id: 'header.menu.connect_owner' })
          }
        ]}>
          {t({ id: 'header.menu.connect' })}
        </DropdownMenu>
      </div>
    )

  const mobileButtons = () => (
      <div className="mobileConnectSection">
        <Modal triggerClass="w-full" triggerText={t({ id: 'header.menu.connect' })}>
          <div className="modal-content">
            <h4>
              {t({ id: 'header.connection.modal.title' })}
            </h4>
            <form>
              <div className="input-radio">
                <input value="candidate"
                       id="input-candidate"
                       type="radio"
                       onChange={handleChangeConnection}
                       checked={connectionType === 'candidate'}
                />
                <label htmlFor="input-candidate">
                  {t({ id: 'header.menu.connect_candidate' })}
                </label>
              </div>
              <div className="input-radio">
                <input value="recruiter"
                       id="input-recruiter"
                       type="radio"
                       onChange={handleChangeConnection}
                       checked={connectionType === 'recruiter'}
                />
                <label htmlFor="input-recruiter">
                  {t({ id: 'header.menu.connect_recruiter' })}
                </label>
              </div>
              <div className="input-radio">
                <input value="owner"
                       id="input-owner"
                       type="radio"
                       onChange={handleChangeConnection}
                       checked={connectionType === 'owner'}
                />
                <label htmlFor="input-owner">
                  {t({ id: 'header.menu.connect_owner' })}
                </label>
              </div>
              <Button
                className="w-full"
                linkTo={`https://app.seekube.com/auth/login/${connectionType}`}
              >
                {t({ id: 'header.connection.modal.connect' })}
              </Button>
            </form>
          </div>
        </Modal>
        <Button className="w-full"
                variant="tonal"
                linkTo="https://app.seekube.com/auth/signup/candidate">
          {t({ id: 'header.menu.signup_candidate' })}
        </Button>
      </div>
    )

  return (
    <section className={`herozone ${type}`}>
      <img className="deco2" alt="deco" src={face3}/>
      <img className="deco3" alt="deco" src={face2}/>
      <img className="deco5" alt="deco" src={face1}/>
      <img className="deco6" alt="deco" src={wormblue}/>
      <img className="deco7" alt="deco" src={face4}/>

      <div className="content_flow">
        <img className="deco4" alt="deco" src={seek}/>
        <img className="deco1" alt="deco" src={faces}/>
        <nav>
          <ul>
            <li className="brand">
              <Link to="/"><img src={logoSeekube} alt="Seekube"/></Link>
            </li>
            {
              type === 'home' ? homeLinks()
                : (
                  <>
                    <li>
                      <Link
                        to="/recruiters/events"
                      >
                        {t({ id: 'header.menu.calendar' })}
                      </Link>
                    </li>
                  </>
                )
            }
          </ul>
            {!isMobile && desktopButtons()}
        </nav>
        <div className="content">
          {children}
          {isMobile && mobileButtons()}
        </div>
        {badge}
      </div>
    </section>
  )
};

Header.propTypes = {
  children: any,
  badge: any,
  type: string,
};

export default Header;
