import * as React from 'react';
import { string } from 'prop-types';

import { useFormatMessage } from 'react-intl-hooks';
import Header from "../Header";
import Footer from "../Footer";

const Article = ({ articleId }) => {
  const t = useFormatMessage();

  return (
    <div className="page_template">
      <Header>
        <div className="col-left">
          <div className="text">
            <h1>{t({ id: `${articleId}.title` })}</h1>
          </div>
        </div>
      </Header>
      <section className="article text">
        <div className="content" dangerouslySetInnerHTML={{ __html: t({ id: `${articleId}.content` }) }} />
      </section>
      <Footer />
    </div>
  )
  };

Article.propTypes = {
  articleId: string,
};

export default Article;
